import { ChevronLeft } from "components/LEGACY/styled-components/Icons"
import PlaceholderImage from "components/utils/PlaceholderImage"
import { WithRef } from "hooks/firestore/FirestoreDocument"
import { useOrderMode } from "hooks/misc/useOrderMode"
import gastromiaLogo from "images/gastromia_logo.png"
import Image from "next/image"
import Link from "next/link"
import Shop from "types/firestore/shop"

export default function HeaderLogo({ shop }: { shop: WithRef<Shop> }) {
  const platformLink = shop.platformListed && shop.platformLink
  const orderMode = useOrderMode()
  const isSelfOrder = orderMode === "selfOrder"
  return (
    <>
      <style jsx>{`
        .shopLogoContainer {
          border-radius: 15px;
          border: none;
          padding: 2px;

          display: flex;
          height: 60px;
          width: 60px;
        }
        .shopLogoContainer > :global(span) {
          border-radius: 15px;
        }
        .backButton {
          height: 60px;
          width: 70px;
          margin-right: -15px;
          padding-right: 15px;
          border-radius: 15px 0 0 15px;
          border: none;
          background: var(--dark);
          font-size: 0.6rem;
          line-height: 1.1;
        }
        .iconsWrapper {
          color: var(--white);
          display: flex;
          align-items: center;
          padding: 0 0.25rem 0.25rem 0;
        }
        .headerLogo {
          display: flex;
        }
      `}</style>
      <div className={"headerLogo"}>
        {platformLink && (
          <Link href="in/gross-umstadt" passHref>
            <button className="backButton">
              <div className="iconsWrapper">
                <ChevronLeft color={"currentColor"} size={"21px"} />
                <Image layout="fixed" height="15" width="15" src={gastromiaLogo} alt="gp logo" priority />
              </div>
              weitere
              <br />
              Betriebe
            </button>
          </Link>
        )}
        {shop.image &&
          (isSelfOrder ? (
            <div className="shopLogoContainer">
              <PlaceholderImage
                src={shop.image}
                layout="fixed"
                width={56}
                height={56}
                objectFit="contain"
                alt={shop.name}
              />
            </div>
          ) : (
            <Link
              passHref
              href={
                shop.domain
                  ? `https://${shop.domain}`
                  : `https://${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/${shop?.shorthand || shop?._ref.id}`
              }
            >
              <div className="shopLogoContainer tw-cursor-pointer">
                <PlaceholderImage
                  src={shop.image}
                  layout="fixed"
                  width={56}
                  height={56}
                  objectFit="contain"
                  alt={shop.name}
                />
              </div>
            </Link>
          ))}
      </div>
    </>
  )
}
