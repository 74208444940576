import CategoryGridItem from "components/orderPage/CategorySelectors/CategoryGridItem"
import { useCategoryInView } from "contexts/CategoryInViewContext"
import { isSameDay, parse } from "date-fns"
import { useCategories } from "hooks/firestore/simple/useCategories"
import { useB2BStorefront } from "hooks/localstate/url/useB2BStorefront"
import { useDraggable } from "hooks/misc/useDraggableScroll"
import { useOrderMode } from "hooks/misc/useOrderMode"
import React, { useCallback, useEffect, useRef } from "react"
import ZonedOpeningHours from "utils/ZonedOpeningHours"
import { CatGridWrapper } from "./styles/CategoriesStyles"

const CategoryGrid = () => {
  const categories = useCategories()
  const isB2BStorefront = useB2BStorefront()
  const orderMode = useOrderMode()
  const gridRef = useRef<HTMLDivElement>(null)
  const { inViewCategoryId } = useCategoryInView()

  const cats = categories
    ?.filter(category => isB2BStorefront || !category.isB2BOnly)
    .filter(category => !!category.visible)
    .filter(category => !category.hideForOrderModes || !category.hideForOrderModes.includes(orderMode))
    .filter(category => {
      if (!category.hiddenDays || category.hiddenDays.length === 0) {
        return true
      }

      const todayIsHidden = category.hiddenDays.some(day => {
        const date = parse(day, "yyyy-MM-dd", new Date())
        return isSameDay(date, new Date())
      })

      return !todayIsHidden
    })
    .filter(category => {
      const availability = category.availability

      if (!availability || availability.mode === "off") {
        return true
      }

      const oh = new ZonedOpeningHours(availability.value, "Europe/Berlin")
      return oh.getState(new Date())
    })
    .sort((a, b) => a.position - b.position)

  const scrollToCategory = useCallback((categoryId: string) => {
    const element = document.getElementById(`cat_${categoryId}`)
    if (element) {
      const topMargin = 170 // Adjust this value as needed
      const elementPosition = element.getBoundingClientRect().top
      const offsetPosition = elementPosition + window.scrollY - topMargin

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      })
    }
  }, [])

  useEffect(() => {
    if (inViewCategoryId && gridRef.current) {
      const categoryElement = gridRef.current.querySelector(`[data-category-id="${inViewCategoryId}"]`) as HTMLElement
      if (categoryElement) {
        const scrollLeft =
          categoryElement.offsetLeft - gridRef.current.offsetWidth / 2 + categoryElement.offsetWidth / 2
        gridRef.current.scrollTo({
          left: scrollLeft,
          behavior: "smooth",
        })
      }
    }
  }, [inViewCategoryId])

  const { events } = useDraggable(gridRef as React.MutableRefObject<HTMLDivElement>, {
    decayRate: 0.97,
    safeDisplacement: 11,
  })

  return cats && cats.length > 1 ? (
    <CatGridWrapper ref={gridRef} {...events}>
      {cats.map(cat => (
        <CategoryGridItem key={cat._ref.id} cat={cat} onClick={() => scrollToCategory(cat._ref.id)} />
      ))}
    </CatGridWrapper>
  ) : null
}

export default React.memo(CategoryGrid)
