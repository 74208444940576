import React, { createContext, ReactNode, useContext, useState } from "react"

interface CategoryInViewContextType {
  inViewCategoryId: string | null
  setInViewCategoryId: (id: string | null) => void
}

const CategoryInViewContext = createContext<CategoryInViewContextType | undefined>(undefined)

export const CategoryInViewProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [inViewCategoryId, setInViewCategoryId] = useState<string | null>(null)

  return (
    <CategoryInViewContext.Provider value={{ inViewCategoryId, setInViewCategoryId }}>
      {children}
    </CategoryInViewContext.Provider>
  )
}

export const useCategoryInView = () => {
  const context = useContext(CategoryInViewContext)
  if (context === undefined) {
    throw new Error("useCategoryInView must be used within a CategoryInViewProvider")
  }
  return context
}
