import { addLineBreaks } from "components/orderPage/functions/general"
import {
  extractProductInventory,
  getPriceMetadata,
  parseProductGroupImageURL,
  parseProductGroupName,
} from "components/orderPage/functions/products"
import ProductTile from "components/orderPage/ProductTile"
import { WithRef } from "hooks/firestore/FirestoreDocument"
import { useBasket } from "hooks/firestore/simple/useBasket"
import { useStorefrontLocation } from "hooks/localstate/url/useStorefrontLocation"
import { useOrderMode } from "hooks/misc/useOrderMode"
import React from "react"
import { Category } from "types/firestore/category"
import ProductGroup from "types/firestore/productGroup"

interface ProductgroupProps {
  product?: WithRef<ProductGroup>
  category?: WithRef<Category>
}

const Productgroup = ({ product, category }: ProductgroupProps) => {
  const loading = !product
  const basket = useBasket()
  const { location, setLocation } = useStorefrontLocation()

  const visibleProductItems =
    product?.items?.filter(
      elem =>
        elem.visible &&
        (elem?.inventory?.trackingActive
          ? elem?.inventory?.currentInventory >
            (basket?.items?.reduce((acc, curr) => {
              if (curr.id === elem.id) {
                if (curr.localUnit === "piece" && curr.unit !== "piece") {
                  return acc + curr.quantity * curr.unitQuantity
                } else {
                  return acc + curr.quantity
                }
              }
              return acc
            }, 0) ?? 0)
          : true)
    ) || []

  const startProductModal = () => {
    product &&
      setLocation({
        categoryIds: location.categoryIds,
        productGroupId: product._ref.id,
      })
  }

  const orderMode = useOrderMode()

  const { price, unit, unitsAreDifferent, pricesAreDifferent, discountRate, basePrice } = getPriceMetadata(
    visibleProductItems,
    orderMode,
    category?.discount
  )
  const differentPrices = pricesAreDifferent
  const differentUnits = unitsAreDifferent
  const differencesInPricesExist = differentPrices || differentUnits

  const productName = product && parseProductGroupName(product)
  const image = product && (parseProductGroupImageURL(product) || category?.image)

  const productDesc = addLineBreaks(
    (visibleProductItems.length > 1 ? product?.description : visibleProductItems[0]?.description) || ""
  )

  const productInventory = product && extractProductInventory({ product, basketItems: basket?.items ?? [] })

  return !product || product?.visible ? (
    <>
      <ProductTile
        onClick={startProductModal}
        image={image}
        title={productName}
        desc={productDesc}
        beforePrice={(differencesInPricesExist || undefined) && "ab"}
        price={price}
        unit={unit}
        soldOut={loading ? false : !product?.visible || visibleProductItems.length === 0}
        loading={loading}
        inventoryNotice={productInventory?.label}
        discountRate={discountRate}
        basePrice={basePrice}
      />
    </>
  ) : null
}

export default React.memo(Productgroup)
