import CategoryGrid from "components/orderPage/CategorySelectors/CategoryGrid"
import { categoryIsParentingProducts } from "components/orderPage/functions/categories"
import { isSameDay, parse } from "date-fns"
import { useCategories } from "hooks/firestore/simple/useCategories"
import { useB2BStorefront } from "hooks/localstate/url/useB2BStorefront"
import { useStorefrontLocation } from "hooks/localstate/url/useStorefrontLocation"
import { useOrderMode } from "hooks/misc/useOrderMode"
import React, { useEffect, useState } from "react"
import ZonedOpeningHours from "utils/ZonedOpeningHours"
import { CatWrapper } from "./styles/CategoriesStyles"

function CategorySelectors() {
  const { location } = useStorefrontLocation()

  const categories = useCategories()
  const parentId = location.categoryIds?.[location.categoryIds.length - 1] || ""

  const isB2BStorefront = useB2BStorefront()
  const orderMode = useOrderMode()

  const cats = categories
    ?.filter(category => parentId === category.parent && categoryIsParentingProducts(category, categories))
    .filter(category => isB2BStorefront || !category.isB2BOnly)
    .filter(category => !category.hideForOrderModes || !category.hideForOrderModes.includes(orderMode))
    .filter(category => {
      if (!category.hiddenDays || category.hiddenDays.length === 0) {
        return true
      }

      const todayIsHidden = category.hiddenDays.some(day => {
        const date = parse(day, "yyyy-MM-dd", new Date())
        return isSameDay(date, new Date())
      })

      return !todayIsHidden
    })
    .filter(category => {
      const availability = category.availability

      if (!availability || availability.mode === "off") {
        return true
      }

      const oh = new ZonedOpeningHours(availability.value, "Europe/Berlin")
      return oh.getState(new Date())
    })
    .sort((a, b) => a.position - b.position)

  const [isSticky, setIsSticky] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const sticky = window.scrollY > 100
      setIsSticky(sticky)
    }

    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  return cats && cats.length > 1 ? (
    <CatWrapper
      sticky
      style={{
        backgroundColor: isSticky ? "rgba(255, 255, 255, 0.3)" : "transparent",
        backdropFilter: isSticky ? "blur(10px)" : "none",
        transition: "backdrop-filter 0.5s ease, background-color 0.5s ease",
      }}
    >
      <CategoryGrid />
    </CatWrapper>
  ) : null
}

export default React.memo(CategorySelectors)
