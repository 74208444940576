import round from "lodash/round"
import uniq from "lodash/uniq"
import React from "react"
import { ItemInBasket } from "types/firestore/basket"
import { Category } from "types/firestore/category"
import { Item, PieceLabel } from "types/firestore/item"
import ProductGroup, { ItemInProductGroup } from "types/firestore/productGroup"

export const parseProductGroupName = (productGroup: ProductGroup, itemId?: string) => {
  const product = productGroup
  const items = product?.items || []
  const visibleItems = items.filter(item => item.visible)
  if (product && itemId) {
    const item = visibleItems.find(item => item.id === itemId)
    return `${items.length > 1 ? `${product.name}: ` : ""}${item?.name}`
  }
  if (product) {
    if (visibleItems.length > 1) {
      return product.name
    }
    if (visibleItems.length === 1) {
      return `${items.length > 1 ? `${product.name}: ` : ""}${visibleItems[0].name}`
    }
    if (visibleItems.length === 0) {
      return `${items.length > 1 ? `${product.name}` : `${items ? items[0]?.name : ""}`}`
    }
  }
  return ""
}

export const parseProductGroupImageURL = (productGroup: ProductGroup, itemId?: string) => {
  const product = productGroup
  const items = product?.items || []
  const visibleItems = items.filter(item => item.visible)

  const imageItemId = itemId || (items.length === 1 && visibleItems.length === 1 ? visibleItems[0].id : undefined)

  if (imageItemId) {
    const item = visibleItems.find(item => item.id === imageItemId)
    if (item?.image) {
      if (typeof item?.image === "string") {
        return { url: item.image }
      }
      if (item?.image?.url) {
        return item.image
      }
    }
  }

  if (product?.image?.url) {
    return product.image
  }

  const imageUrl =
    visibleItems.map(item => item.image).find(el => el) || items.map(item => item.image).find(el => el) || undefined

  return typeof imageUrl === "string" ? { url: imageUrl } : imageUrl
}

export const useProductGroupSelectedOptions = (selectedOptions: { name: string }[] = []) => {
  return selectedOptions.length ? selectedOptions.map(choice => choice.name).join(", ") : ""
}

// Providing the base price of a given item if requested and available
export function formatBasePrice(item?: Item): React.ReactNode {
  if (item && item.unit === "piece" && item.unitDisplayFactor && item.unitQuantity && item.unitDisplay) {
    return (
      <>
        <p style={{ marginBottom: "0", fontSize: "0.9rem" }}>
          Grundpreis: {round(((item.price / item.unitQuantity) * 1000) / 100, 2).toFixed(2)} €/
          {
            // item.unitDisplayFactor === 1000
            //   ?
            (item.unitDisplay === "g" && "kg") || (item.unitDisplay === "ml" && "l") || "Einheit"
            // : `${item.unitDisplayFactor}${item.unitDisplay}`
          }
        </p>
        <p style={{ marginBottom: "0", fontSize: "0.9rem" }}>
          {labelForPiece(item.pieceLabel).one} entspricht ca.{" "}
          {item.unitQuantity > 1000
            ? `${round(item.unitQuantity / 1000, 2)} ${
                item.unitDisplay === "g" ? "kg" : item.unitDisplay === "ml" ? "Liter" : "x"
              }`
            : `${item.unitQuantity}${item.unitDisplay}`}
        </p>
      </>
    )
  }
  return ""
}

// Get the correct label for pieces
export function labelForPiece(pieceLabel?: PieceLabel) {
  switch (pieceLabel) {
    case "piece":
      return { sing: "Stück", plur: "Stück", one: "Ein Stück" }
    case "slice":
      return { sing: "Scheibe", plur: "Scheiben", one: "Eine Scheibe" }
    case "pack":
      return { sing: "Paket", plur: "Pakete", one: "Ein Paket" }
    case "box":
      return { sing: "Kiste", plur: "Kisten", one: "Eine Kiste" }
    case "bag":
      return { sing: "Tüte", plur: "Tüten", one: "Eine Tüte" }
    case "serving":
      return { sing: "Portion", plur: "Portionen", one: "Eine Portion" }
    case "bunch":
      return { sing: "Bund", plur: "Bund", one: "Ein Bund" }
    case "bottle":
      return { sing: "Flasche", plur: "Flaschen", one: "Eine Flasche" }
    case "hand":
      return { sing: "Handvoll", plur: "Handvoll", one: "Eine Handvoll" }
    case "sac":
      return { sing: "Sack", plur: "Säcke", one: "Ein Sack" }
    case "glass":
      return { sing: "Glas", plur: "Gläser", one: "Ein Glas" }
    case "ring":
      return { sing: "Ring", plur: "Ringe", one: "Ein Ring" }
    case "bowl":
      return { sing: "Schale", plur: "Schalen", one: "Eine Schale" }
    case "basket":
      return { sing: "Korb", plur: "Körbe", one: "Ein Korb" }
    case "pouch":
      return { sing: "Beutel", plur: "Beutel", one: "Ein Beutel" }
    case "panicle":
      return { sing: "Rispe", plur: "Rispen", one: "Eine Rispe" }
    case "cluster":
      return { sing: "Traube", plur: "Trauben", one: "Eine Traube" }
    default:
      return { sing: "Stück", plur: "Stücke", one: "Ein Stück" }
  }
}

export const getPriceMetadata_LEGACY = (
  productGroup: ProductGroup,
  orderMode?: "pickup" | "delivery" | "selfOrder",
  discount?: Category["discount"]
) => {
  const visibleProductItems = productGroup?.items ? productGroup.items.filter(elem => elem.visible) : []
  return getPriceMetadata(visibleProductItems, orderMode, discount)
}

export const getPriceMetadata = (
  visibleProductItems: ItemInProductGroup[],
  orderMode?: "pickup" | "delivery" | "selfOrder",
  discount?: Category["discount"]
) => {
  if (!visibleProductItems.length) {
    return {
      price: "",
      unit: "",
      pricesAreDifferent: false,
      unitsAreDifferent: false,
      strikePrice: 0,
      discountRate: 0,
    }
  }

  const getPriceForMode = (item: ItemInProductGroup) => {
    switch (orderMode) {
      case "delivery":
        return item.priceInfo.deliveryPrice ?? item.priceInfo.price
      case "selfOrder":
        return item.priceInfo.selfOrderPrice ?? item.priceInfo.price
      default:
        return item.priceInfo.price
    }
  }

  const priceArray = visibleProductItems.map(item => getPriceForMode(item))

  const i = priceArray.indexOf(Math.min(...priceArray))
  const cheapestItem = i ? visibleProductItems[i] : visibleProductItems[0]

  const price =
    cheapestItem?.priceInfo.unit === "piece"
      ? getPriceForMode(cheapestItem)
      : (getPriceForMode(cheapestItem) / 1000) * 1000

  let displayUnit
  let basePrice
  const {
    unit: cheapestItemUnit,
    unitDisplayFactor: cheapestItemUnitDisplayFactor,
    unitQuantity,
    unitDisplay,
  } = cheapestItem.priceInfo
  if (cheapestItemUnit === "piece") {
    displayUnit = "Stück"
    if (unitQuantity) {
      if (cheapestItemUnitDisplayFactor > 0) {
        basePrice = `${round(((price / unitQuantity) * 1000) / 100, 2).toFixed(2)}€/${
          unitDisplay === "g" ? "Kilo" : "Liter"
        }`
      }
    }
  } else {
    displayUnit = cheapestItemUnit === "g" ? "Kilo" : "Liter"
  }

  const pricesAreDifferent = uniq(priceArray).length > 1
  const unitsAreDifferent = uniq(visibleProductItems.map(item => item?.priceInfo.unit)).length > 1

  const strikePrice = !cheapestItem?.priceInfo.strikePrice
    ? undefined
    : cheapestItem?.priceInfo.unit === "piece"
      ? cheapestItem?.priceInfo.strikePrice
      : (cheapestItem?.priceInfo.strikePrice / 1000) * (cheapestItem?.priceInfo.unitDisplayFactor ? 1000 : 0)

  const displayStrikePrice = strikePrice && round(strikePrice / 100, 2).toFixed(2)
  let discountedPrice = price
  let discountRate = strikePrice && Math.max(0, round((1 - price / strikePrice) * 100, 0))

  if (!strikePrice && discount) {
    if (discount.type === "percent") {
      discountedPrice = price * (1 - discount.amount / 100)
    } else if (discount.type === "cent") {
      discountedPrice = price - discount.amount
    }
    discountRate = Math.max(0, round((1 - discountedPrice / price) * 100, 0))
  }

  if (discountRate && strikePrice && discountRate < 10 && price - strikePrice < 100) {
    discountRate = 0
  }

  // Use discountedPrice if no strikePrice is active
  const finalPrice = strikePrice ? price : discountedPrice
  const finalDisplayPrice = round(finalPrice / 100, 2).toFixed(2)

  return {
    price: finalDisplayPrice,
    unit: displayUnit,
    pricesAreDifferent,
    unitsAreDifferent,
    strikePrice: displayStrikePrice,
    discountRate,
    basePrice,
  }
}

export const extractProductInventory = ({
  product,
  itemId,
  basketItems,
}: {
  product: ProductGroup
  itemId?: string
  basketItems: ItemInBasket[]
}) => {
  let items = product.items
  const itemsInBasket = basketItems || []

  if (itemId) {
    items = items.filter(item => item.id === itemId)
  }

  const visibleProductItems = items?.filter(elem => elem.visible) || []
  if (!product?.visible || visibleProductItems.length === 0) {
    // Sold out
    return { label: "Zurzeit nicht verfügbar", inventory: 0, unit: undefined }
  }

  if (!items) return { label: "" }
  const inventories = items.map(item => {
    const inventory = item.inventory
    return { itemId: item.id, ...inventory }
  })
  const itemsWithInventories = inventories
    .filter(inventory => inventory.trackingActive)
    .map(inventory => inventory.itemId)
  const itemsWithPublicInventories = inventories
    .filter(inventory => inventory.showInventoryInShop)
    .map(inventory => inventory.itemId)

  const parseUnit = (item: ItemInProductGroup) => (item.priceInfo.unit === "piece" ? "x" : item.priceInfo.unit)

  if (itemsWithInventories.length === 0) {
    return { label: "", inventory: undefined, unit: undefined }
  }
  if (items.length === 1) {
    const item = items[0]
    const inventory = item.inventory
    let quantityInBasket = 0
    itemsInBasket.forEach(it => {
      if (it.id === item.id) {
        if (it.unit === "piece") {
          quantityInBasket += it.quantity
        } else {
          if (it.localUnit === "piece") {
            quantityInBasket += it.quantity * it.unitQuantity
          } else {
            quantityInBasket += it.quantity
          }
        }
      }
    })
    // ALTERNATIVELY
    // const quantityInBasket = (basketItems?.reduce((acc, curr) => {
    //       if (curr.id === item.id) {
    //         if (curr.localUnit === "piece") {
    //           return acc + curr.quantity * curr.unitQuantity
    //         } else {
    //           return acc + curr.quantity
    //         }
    //       }
    //       return acc
    //     }, 0) ?? 0)

    if (inventory && inventory.showInventoryInShop) {
      let unit = parseUnit(item)

      let currentInventory = Math.max(0, inventory.currentInventory - quantityInBasket)
      // Before rounding
      const originalQuantityInBasket = quantityInBasket

      if ((unit === "g" || unit === "ml") && currentInventory >= 1000) {
        currentInventory = round(currentInventory / 1000, 2)

        quantityInBasket = round(quantityInBasket / 1000, 2)
        unit = unit === "g" ? "kg" : "ml"
      }

      return {
        label:
          currentInventory === 0
            ? "ausverkauft"
            : `Nur noch ${currentInventory} ${unit} verfügbar${
                quantityInBasket > 0 ? ` (${quantityInBasket} ${unit} im Warenkorb)` : ""
              }`,
        inventory: currentInventory,
        unit: parseUnit(item),
        quantityInBasket: originalQuantityInBasket,
      }
    }

    return {
      label: (inventory?.currentInventory ?? 1) > quantityInBasket ? "" : "ausverkauft",
      inventory: inventory?.currentInventory === undefined ? undefined : inventory?.currentInventory - quantityInBasket,
      unit: parseUnit(item),
      quantityInBasket: quantityInBasket,
    }
  }

  if (items.length > itemsWithInventories.length) {
    if (itemsWithPublicInventories.length === 0) {
      return {
        label: "Teilweise limitierte Verfügbarkeiten",
        inventory: undefined,
        unit: undefined,
        quantityInBasket: undefined,
      }
    }
    // On this level, we can't name specific inventories anyway, as more than one item is referenced
    return {
      label: "Teilweise limitierte Verfügbarkeiten",
      inventory: undefined,
      unit: undefined,
      quantityInBasket: undefined,
    }
  }

  if (itemsWithPublicInventories.length < itemsWithInventories.length) {
    return {
      label: "Teilweise limitierte Verfügbarkeiten",
      inventory: undefined,
      unit: undefined,
      quantityInBasket: undefined,
    }
  }
  // All items in group have limited public inventory
  return { label: "Limitierte Verfügbarkeiten", inventory: undefined, unit: undefined, quantityInBasket: undefined }
}
