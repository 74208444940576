import { startSignUpWithEmail } from "actions/authFunctions"
import InputField from "components/utils/InputField"
import GetpackedLoginButton from "components/utils/loginButtons/GetpackedLoginButton"
import { useLastShop } from "hooks/misc/useLastShop"
import { useState } from "react"

interface EmailSignUpFormProps {
  defaultEmail?: string
  extraEmailValidator?: (email: string) => boolean
  skipEmailVerification?: boolean
}

const EmailSignUpForm = ({ defaultEmail, extraEmailValidator, skipEmailVerification }: EmailSignUpFormProps) => {
  const lastShop = useLastShop()
  const [email, setEmail] = useState(defaultEmail || "")
  const [passwordA, setPasswordA] = useState("")
  const [passwordB, setPasswordB] = useState("")

  const [asyncError, setAsyncError] = useState<undefined | "email" | "other" | "mismatch">(undefined)

  const handleSignUp = async () => {
    if (passwordA !== passwordB) {
      setAsyncError("mismatch")
      return
    }
    if (extraEmailValidator && !extraEmailValidator(email)) {
      setAsyncError("email")
      return
    }
    setAsyncError(undefined)
    try {
      const user = {
        email: email,
        password: passwordA,
      }
      await startSignUpWithEmail(user, lastShop || "welcome", { skipEmailVerification })
    } catch (error) {
      if (error) {
        console.error(error)
        //TODO: Adjust for signup errors, this is a login error
        setAsyncError((error as Error).message.includes("auth/user-not-found") ? "email" : "other")
      }
    }
  }

  const error = passwordA && passwordA !== passwordB ? "mismatch" : asyncError

  const errorMessage =
    error &&
    {
      email: "Versuch es mit einer anderen E-Mail oder logge dich ein",
      mismatch: "Die Passwörter stimmen nicht überein",
      other: "Ein Fehler ist aufgetreten, versuche es nachher nochmal",
    }[error]

  const buttonEnabled = email && passwordA === passwordB

  return (
    <>
      <style jsx>{`
        @keyframes slidein {
          from {
            max-height: 0px;
            opacity: 0;
          }

          to {
            max-height: 24px;
            opacity: 1;
          }
        }
        .errorMessage {
          margin: 0;
          max-height: 24px;
          opacity: 1;
          animation: slidein 0.15s linear;
        }
      `}</style>
      <InputField danger={error === "email"} type="email" value={email} onChange={e => setEmail(e.target.value)}>
        E-Mail Adresse
      </InputField>
      <InputField
        danger={error === "mismatch"}
        type="password"
        value={passwordA}
        onChange={e => setPasswordA(e.target.value)}
        name="password"
        autoComplete="new-password"
      >
        Passwort
      </InputField>
      <InputField
        danger={error === "mismatch"}
        type="password"
        value={passwordB}
        onChange={e => setPasswordB(e.target.value)}
        name="password"
        autoComplete="new-password"
      >
        Passwort wiederholen
      </InputField>

      {errorMessage ? <p className="errorMessage">{errorMessage}</p> : null}
      <GetpackedLoginButton onClick={handleSignUp} disabled={!buttonEnabled} signUp />
    </>
  )
}

export default EmailSignUpForm
