import PlaceholderImage from "components/utils/PlaceholderImage"
import { useCategoryInView } from "contexts/CategoryInViewContext"
//@ts-expect-error: Declaration missing
import { onlyEmoji, withoutEmoji } from "emoji-aware"
import { WithRef } from "hooks/firestore/FirestoreDocument"
import React from "react"
import { Category } from "types/firestore/category"
import {
  GridEmoji,
  GridHeadline,
  GridImage,
  GridImageWrapper,
  GridItem,
  GridItemContent,
  GridItemInner,
} from "./styles/CategoriesStyles"

interface CategoryGridItemProps {
  cat: WithRef<Category>
  onClick: () => void
}

const CategoryGridItem = ({ cat, onClick }: CategoryGridItemProps) => {
  const { inViewCategoryId } = useCategoryInView()
  const isHighlighted = cat._ref.id === inViewCategoryId

  return (
    <GridItem onClick={onClick} data-category-id={cat._ref.id}>
      <GridItemInner>
        <GridItemContent highlighted={isHighlighted}>
          {cat?.image?.url && (
            <GridImageWrapper>
              <GridImage>
                <PlaceholderImage
                  src={cat?.image}
                  alt={`Kategoriebild`}
                  layout="intrinsic"
                  width="350"
                  height="250"
                  objectFit="cover"
                  objectPosition="50% 50%"
                  draggable="false"
                />
              </GridImage>
            </GridImageWrapper>
          )}
          <GridEmoji bgIcon={true}>{onlyEmoji(cat.name)}</GridEmoji>
          <GridHeadline highlighted={isHighlighted} style={!cat.description ? { marginBottom: 0 } : {}}>
            {withoutEmoji(cat.name)}
          </GridHeadline>
        </GridItemContent>
      </GridItemInner>
    </GridItem>
  )
}

export default React.memo(CategoryGridItem)
