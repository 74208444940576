import { useShop } from "hooks/firestore/simple/useShop"
import { useUser } from "hooks/firestore/simple/useUser"
import { useB2BStorefront } from "hooks/localstate/url/useB2BStorefront"
import { useRouter } from "next/router"
// eslint-disable-next-line css-modules/no-unused-class
import { usePrivateUser } from "hooks/firestore/simple/usePrivateUser"
import styles from "./SwitchBetweenB2BAndNormalModeButton.module.scss"

const SwitchBetweenB2BAndNormalModeButton = () => {
  const user = useUser()
  const privateUser = usePrivateUser()

  const shop = useShop()

  const router = useRouter()
  const isB2BStorefront = useB2BStorefront()

  // Check if private user has all data filled out
  const hasAllB2BDataFilledOut = privateUser?.b2b?.businessName && privateUser?.b2b?.vatId && privateUser?.b2b?.address

  if (!user || !shop || !shop.b2bCustomers?.some(c => c.id === user._ref.id) || !hasAllB2BDataFilledOut) {
    return null
  }

  return (
    <button
      className={styles.switchButton}
      onClick={() => {
        router.push({
          query: {
            ...router.query,
            b2b: !isB2BStorefront,
          },
        })
      }}
    >
      {isB2BStorefront ? "Zurück zum normalen Modus" : "Zu B2B Modus"}
    </button>
  )
}

export default SwitchBetweenB2BAndNormalModeButton
