import { ExpandLess, ExpandMore } from "components/LEGACY/styled-components/Icons"
import {
  CategoryArrowButton,
  CategoryContainer,
  CategoryDescription,
  CategoryHeader,
  CategoryTitle,
  CatMeta,
} from "components/LEGACY/styled-components/Storefront"
import Productgroup from "components/orderPage/CategoryDisplay/Productgroup"
import { useCategoryInView } from "contexts/CategoryInViewContext"
import { isSameDay, parse } from "date-fns"
import { useCategoryVisibleProductGroups } from "hooks/firestore/advanced/useCategoryVisibleProductGroups"
import { WithRef } from "hooks/firestore/FirestoreDocument"
import { useCategory } from "hooks/firestore/simple/useCategory"
import { useB2BStorefront } from "hooks/localstate/url/useB2BStorefront"
import useInView from "hooks/misc/useInVIew"
import { useOrderMode } from "hooks/misc/useOrderMode"
import truncate from "lodash/truncate"
import React, { useEffect, useState } from "react"
import ProductGroup from "types/firestore/productGroup"
import ZonedOpeningHours from "utils/ZonedOpeningHours"

interface ProductGroupCategoryProps {
  categoryId: string
}

const ProductGroupCategory = ({ categoryId }: ProductGroupCategoryProps) => {
  const { ref, inView } = useInView({
    rootMargin: "0px",
    root: null,
    threshold: 0,
  })

  const [hasBeenInView, setHasBeenInView] = useState(false)
  const { ref: catMetaRef, inView: catMetaInView } = useInView({
    root: null,
    threshold: 0.2,
    rootMargin: "-170px 0px -60% 0px",
  })

  const { setInViewCategoryId } = useCategoryInView()
  const category = useCategory(categoryId)
  const visibleProductGroups = useCategoryVisibleProductGroups(hasBeenInView ? categoryId : undefined)
  const [foldOpen, setFoldOpen] = useState(true)
  const catDesc =
    category &&
    truncate(category.description || "", {
      length: foldOpen ? 20000 : 100,
    })

  const orderMode = useOrderMode()

  useEffect(() => {
    if (inView) {
      setHasBeenInView(true)
    }
  }, [inView])

  const filteredProductGroups = orderMode
    ? visibleProductGroups
        ?.filter(pg => !pg.hideForOrderModes?.includes(orderMode))
        .filter(pg => {
          if (!pg.hiddenDays || pg?.hiddenDays?.length === 0) {
            return true
          }

          const todayIsHidden = pg?.hiddenDays?.some(day => {
            const date = parse(day, "yyyy-MM-dd", new Date())
            return isSameDay(date, new Date())
          })

          return !todayIsHidden
        })
        .filter(pg => {
          const availability = pg.availability

          if (!availability || availability.mode === "off") {
            return true
          }

          const oh = new ZonedOpeningHours(availability.value, "Europe/Berlin")
          return oh.getState(new Date())
        })
    : visibleProductGroups
        ?.filter(pg => {
          if (!pg.hiddenDays || pg?.hiddenDays?.length === 0) {
            return true
          }

          const todayIsHidden = pg?.hiddenDays?.some(day => {
            const date = parse(day, "yyyy-MM-dd", new Date())
            return isSameDay(date, new Date())
          })

          return !todayIsHidden
        })
        .filter(pg => {
          const availability = pg.availability

          if (!availability || availability.mode === "off") {
            return true
          }

          const oh = new ZonedOpeningHours(availability.value, "Europe/Berlin")
          return oh.getState(new Date())
        })
  const productGroups = filteredProductGroups
  const expectedNumberOfProductGroups = category?.visibleProductGroupCount ?? category?.productGroups?.length ?? 0
  const renderedProductGroups: (WithRef<ProductGroup> | undefined)[] | undefined =
    (productGroups?.length && productGroups) || Array<undefined>(expectedNumberOfProductGroups).fill(undefined)

  const isB2BStorefront = useB2BStorefront()

  const hideCategoryBecauseOfOrderMode = category?.hideForOrderModes
    ? category.hideForOrderModes.includes(orderMode)
    : false

  const isHiddenToday = category?.hiddenDays?.some(day => {
    const date = parse(day, "yyyy-MM-dd", new Date())
    return isSameDay(date, new Date())
  })

  const isAvailableNow = category?.availability
    ? new ZonedOpeningHours(category.availability.value, "Europe/Berlin").getState(new Date())
    : true

  useEffect(() => {
    if (catMetaInView) {
      setInViewCategoryId(categoryId)
    }
  }, [catMetaInView, categoryId, setInViewCategoryId])

  return !hideCategoryBecauseOfOrderMode &&
    !isHiddenToday &&
    isAvailableNow &&
    category?.visible &&
    category.parentVisible &&
    (isB2BStorefront || !category.isB2BOnly) ? (
    <CategoryContainer ref={ref} id={`cat_${categoryId}`}>
      <CategoryHeader onClick={() => setFoldOpen(!foldOpen)}>
        <CategoryArrowButton>
          {foldOpen ? <ExpandLess color={"currentColor"} size="28" /> : <ExpandMore color={"currentColor"} size="28" />}
        </CategoryArrowButton>
        <CatMeta ref={catMetaRef}>
          <CategoryTitle>{category?.name}</CategoryTitle>
          {catDesc && <CategoryDescription>{catDesc}</CategoryDescription>}
        </CatMeta>
      </CategoryHeader>
      {foldOpen &&
        renderedProductGroups?.map((product, index) => (
          <Productgroup key={index} product={product} category={category} />
        ))}
    </CategoryContainer>
  ) : null
}

export default React.memo(ProductGroupCategory)
