import { useEffect, useState } from "react"

interface UserLocation {
  lat: number
  lng: number
}

export function useUserLocation() {
  const [location, setLocation] = useState<UserLocation | null>(null)
  const [loading, setLoading] = useState(true)

  const askForLocation = () => {
    setLoading(true)
    navigator.geolocation.getCurrentPosition(
      position => {
        setLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        })
        setLoading(false)
      },
      error => {
        console.error("Error getting location:", error)
        setLocation(null)
        setLoading(false)
      }
    )
  }

  useEffect(() => {
    askForLocation()
  }, [])

  return { location, loading, askForLocation }
}
