import { useUser } from "hooks/firestore/simple/useUser"
import router from "next/router"
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./MyOrdersButton.module.scss"

const MyOrdersButton = () => {
  const user = useUser()

  if (!user) return null

  return (
    <button
      className={styles.switchButton}
      onClick={() => {
        router.push("/my-orders")
      }}
    >
      Meine Bestellungen
    </button>
  )
}

export default MyOrdersButton
