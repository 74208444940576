import isPointInPolygon from "@turf/boolean-point-in-polygon"
import buffer from "@turf/buffer"
import { point } from "@turf/helpers"
import AlertDialog from "components/utils/AlertDialog"

import { useShop } from "hooks/firestore/simple/useShop"
import { useOrderMode } from "hooks/misc/useOrderMode"
import { useUserLocation } from "hooks/misc/useUserLocation"
import Link from "next/link"
import { useEffect, useMemo, useState } from "react"

const RADIUS_IN_METERS = 500

/**
 * This component checks if the user is within the location bounds of the shop, when the orderMode is "selfOrder".
 *
 * If not, a message is shown to the user, prompting them to go to pickup/delivery or order directly through a waiter.
 */
export default function LocationBoundsChecker() {
  const orderMode = useOrderMode()
  const shop = useShop()

  const shopCoordinates =
    shop?.contact.latitude && shop?.contact.longitude
      ? {
          lat: shop.contact.latitude,
          lng: shop.contact.longitude,
        }
      : null

  if (orderMode !== "selfOrder" || !shop?.selfOrderLocationCheck || !shopCoordinates) return null

  return <LocationCheck shopCoordinates={shopCoordinates} domain={shop.domain} />
}

function LocationCheck({
  shopCoordinates,
  domain,
}: {
  shopCoordinates: { lat: number; lng: number }
  domain?: string
}) {
  const [open, setOpen] = useState(false)
  const { loading, location, askForLocation } = useUserLocation()

  // Check if the user is within the location bounds of the shop
  const shopPoint = useMemo(() => point([shopCoordinates.lng, shopCoordinates.lat]), [shopCoordinates])
  const userPoint = useMemo(() => (location ? point([location.lng, location.lat]) : null), [location])
  const shopBuffer = useMemo(
    () => buffer(shopPoint, RADIUS_IN_METERS, { units: "meters" }),
    [shopPoint, RADIUS_IN_METERS]
  )

  useEffect(() => {
    // If not loading but no location, show dialog for missing permissions
    if (loading || !location) {
      setOpen(true)
      return
    }

    // Existing location check
    if (!shopBuffer || !userPoint) return
    setOpen(!isPointInPolygon(userPoint, shopBuffer))
  }, [loading, location, userPoint, shopBuffer])

  console.log({
    open,
    location,
    shopBuffer,
    userPoint,
    loading,
  })

  return (
    <AlertDialog
      isOpen={open}
      title={loading ? "Lade Standort..." : "Achtung!"}
      description={
        loading
          ? "Wir laden Ihren Standort, um zu prüfen, ob Sie sich im Restaurant befinden..."
          : location
            ? "Unser Standort-Test ergab, dass Sie sich außerhalb des Restaurant-Bereichs befinden. Sollten Sie sich im Restaurant befinden, bestellen Sie bitte direkt bei einem Mitarbeiter oder stellen Sie sicher, dass sie den Standort-Zugriff erlaubt haben. Wenn Sie sich nicht im Restaurant befinden, können Sie einfach zurück zur Website gehen."
            : "Bitte erlauben Sie den Standort-Zugriff, um fortzufahren. Sollten Sie sich im Restaurant befinden, bestellen Sie bitte direkt bei einem Mitarbeiter."
      }
      dismissable={false}
    >
      <div className="tw-flex tw-flex-col sm:tw-flex-row sm:tw-justify-end tw-mt-4 tw-gap-x-4 sm:tw-items-center">
        <Link href={`https://${domain}`}>
          <a className="tw-py-2 tw-rounded-lg tw-w-full sm:tw-w-auto">Zurück zur Website</a>
        </Link>
        {!loading && !location && (
          <button
            onClick={askForLocation}
            type="button"
            className="tw-px-4 tw-py-2 tw-rounded-lg tw-w-full sm:tw-w-auto"
            style={{
              background: "var(--gradient)",
              color: "var(--gradientText)",
            }}
          >
            <a>Standort-Zugriff erlauben</a>
          </button>
        )}
      </div>
    </AlertDialog>
  )
}
