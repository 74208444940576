import { CenterWidthContainer, StorefrontPageContainer } from "components/LEGACY/styled-components/Storefront"
import SiteFooter from "components/header-footer/SideFooter"
import CategoryDisplay from "components/orderPage/CategoryDisplay"
import CategorySelectors from "components/orderPage/CategorySelectors"
import CategoryBreadcrumb from "components/orderPage/CategorySelectors/CategoryBreadcrumb"
import CookieBanner from "components/orderPage/CookieBanner"
import FeaturedProducts from "components/orderPage/FeaturedProducts"
import NoticeIfEmptyShop from "components/orderPage/NoticeIfEmptyShop"
import ProductGroupModalLoader from "components/orderPage/ProductGroupModal"
import BasketLoader from "components/orderPage/StickyBasket/BasketLoader"
import TaxNote from "components/orderPage/TaxNote"
import { BasketOpenContextProvider } from "context/orderFormContexts/BasketOpenContext"
import { OrderLoadingModalProvider } from "context/orderFormContexts/OrderLoadingModalContext"
import { CategoryInViewProvider } from "contexts/CategoryInViewContext"
import React from "react"
import { ToastProvider } from "react-toast-notifications"
import LocationBoundsChecker from "./LocationBoundsChecker"
import OrderLoadingModal from "./OrderLoadingModal"
import ShopHeader from "./ShopHeader"

const MainView = () => {
  return (
    <CategoryInViewProvider>
      <style jsx>{`
        main {
          position: relative;
        }
      `}</style>
      <ToastProvider placement="top-center">
        <BasketOpenContextProvider>
          <OrderLoadingModalProvider>
            <StorefrontPageContainer>
              <CookieBanner />
              <CenterWidthContainer>
                <ShopHeader />
                <main>
                  <TaxNote />
                  <NoticeIfEmptyShop />
                  <FeaturedProducts />
                  <CategoryBreadcrumb />
                  <CategorySelectors />
                  <CategoryDisplay />
                </main>
              </CenterWidthContainer>
              <SiteFooter />

              <ProductGroupModalLoader />
              <BasketLoader />
              <LocationBoundsChecker />
              <OrderLoadingModal />
            </StorefrontPageContainer>
          </OrderLoadingModalProvider>
        </BasketOpenContextProvider>
      </ToastProvider>
    </CategoryInViewProvider>
  )
}

export default React.memo(MainView)
