import Link from "next/link"
import { useEffect, useState } from "react"

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false)

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent")
    if (!consent || consent === "false") {
      setShowBanner(true)
    }
  }, [])

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "true")
    setShowBanner(false)
  }

  if (!showBanner) return null

  return (
    <div className="tw-fixed tw-bottom-0 tw-w-full tw-bg-gray-100 tw-border-t tw-text-black tw-p-4 tw-flex tw-justify-between tw-items-center tw-z-[999]">
      <div>
        Wir nutzen Cookies, um zu garantieren, dass unsere Seite gut funktioniert.{" "}
        <Link href="/legal/datenschutz">
          <a>Mehr erfahren</a>
        </Link>
      </div>
      <button
        style={{
          background: "var(--gradient)",
          color: "var(--gradientText)",
        }}
        className="tw-cursor-pointer tw-mb-2 tw-font-bold tw-text-lg tw-rounded-lg tw-px-6 tw-py-3 tw-text-center"
        onClick={handleAccept}
      >
        OK
      </button>
    </div>
  )
}

export default CookieBanner
