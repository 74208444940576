import classNames from "classnames"
import { ProdImgPlaceholder } from "components/orderPage/ProductTile"
import {
  getPriceMetadata,
  parseProductGroupImageURL,
  parseProductGroupName,
} from "components/orderPage/functions/products"
import PlaceholderImage from "components/utils/PlaceholderImage"
import { isSameDay, parse } from "date-fns"
import { useSpecialOffers } from "hooks/firestore/advanced/useSpecialOffers"
import { useShop } from "hooks/firestore/simple/useShop"
import { useStorefrontLocation } from "hooks/localstate/url/useStorefrontLocation"
import { useOrderMode } from "hooks/misc/useOrderMode"
import React from "react"
import ZonedOpeningHours from "utils/ZonedOpeningHours"
import styles from "./FeaturesProducts.module.scss"

const FeaturedProducts = () => {
  const { location, setLocation } = useStorefrontLocation()
  const offersName = useShop()?.offersName || "Aktuelle Angebote"
  const offers = useSpecialOffers(true)
  const orderMode = useOrderMode()

  const filteredOffers = orderMode
    ? offers
        ?.filter(offer => !offer.hideForOrderModes?.includes(orderMode))
        .filter(offer => {
          if (!offer.hiddenDays || offer?.hiddenDays?.length === 0) {
            return true
          }

          const todayIsHidden = offer?.hiddenDays?.some(day => {
            const date = parse(day, "yyyy-MM-dd", new Date())
            return isSameDay(date, new Date())
          })

          return !todayIsHidden
        })
        .filter(offer => {
          const availability = offer.availability

          if (!availability || availability.mode === "off") {
            return true
          }

          const oh = new ZonedOpeningHours(availability.value, "Europe/Berlin")
          return oh.getState(new Date())
        })
    : offers
        ?.filter(offer => {
          if (!offer.hiddenDays || offer?.hiddenDays?.length === 0) {
            return true
          }

          const todayIsHidden = offer?.hiddenDays?.some(day => {
            const date = parse(day, "yyyy-MM-dd", new Date())
            return isSameDay(date, new Date())
          })

          return !todayIsHidden
        })
        .filter(offer => {
          const availability = offer.availability

          if (!availability || availability.mode === "off") {
            return true
          }

          const oh = new ZonedOpeningHours(availability.value, "Europe/Berlin")
          return oh.getState(new Date())
        })

  if (!filteredOffers || filteredOffers.length === 0 || location.categoryIds?.length) {
    return <></>
  }

  return (
    <div className={classNames(styles.shopFeaturedProducts)}>
      <h2>{offersName}</h2>
      <div className={styles.productList}>
        {filteredOffers.map((product, index) => {
          const visibleProductItems = product.items?.filter(i => i.visible) || []
          if (!product.visible || !visibleProductItems.length) {
            return null
          }
          const productName = parseProductGroupName(product)
          const image = parseProductGroupImageURL(product)

          const { price, unit, unitsAreDifferent, pricesAreDifferent, strikePrice, discountRate } = getPriceMetadata(
            visibleProductItems,
            orderMode
          )

          const startProductModal = () => {
            setLocation({
              categoryIds: location.categoryIds,
              productGroupId: product._ref.id,
            })
          }

          const hasStrikePrice = !!discountRate

          return (
            <button onClick={startProductModal} key={product._ref.id} className={styles.productItem}>
              <div className={styles.imgWrapper}>
                {image ? (
                  <PlaceholderImage
                    src={image}
                    layout="fixed"
                    width="142"
                    height="142"
                    alt={product.name}
                    priority={index <= 3}
                  />
                ) : (
                  <ProdImgPlaceholder loading={false} />
                )}
              </div>
              <span className={styles.name}>{productName}</span>
              <span className={styles.price}>
                {unitsAreDifferent || pricesAreDifferent ? <span className={styles.upperSmall}>ab </span> : ""}
                {price} €{unit !== "Stück" ? <span className={styles.upperSmall}> /{unit}</span> : ""}
              </span>
              {hasStrikePrice && (
                <span className={styles.strikePrice}>
                  - {discountRate}% <span>{strikePrice} €</span>
                </span>
              )}
            </button>
          )
        })}
        <div className={styles.lastSpace} />
      </div>
      <h2 className={styles.cat}>Alle Produkte</h2>
    </div>
  )
}

export default React.memo(FeaturedProducts)
