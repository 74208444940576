import MainView from "components/orderPage/MainView"
import { useCurrentUser } from "hooks/localstate/auth/useCurrentUser"

import Theme from "components/styles/Theme"
import CommonHead from "components/utils/CommonHead"
import EmailLoginForm from "components/utils/EmailLoginForm"
import EmailSignUpForm from "components/utils/EmailSignUpForm"
import ShopIdContextProvider from "context/orderFormContexts/ShopIdContext"
import { useDocument } from "hooks/firestore/core/useDocument"
import { getShopRef } from "hooks/firestore/getRefs"
import { useHost } from "hooks/misc/useHost"
import md5 from "md5"
import Head from "next/head"
import { useState } from "react"
import Shop from "types/firestore/shop"

interface NextOrderPageProps {
  shopId: string
}

const NextOrderPage = ({ shopId }: NextOrderPageProps) => {
  // return <span>{shopId}</span>
  const { document: shop } = useDocument<Shop>(shopId && getShopRef(shopId))
  const { origin } = useHost()

  // Generate a consistent random description for google
  const shopHash = md5(shopId, { asBytes: true })
  const where =
    shopHash[0] < 63
      ? "auf deinem Smartphone oder Computer"
      : shopHash[0] < 127
        ? "von deinem Smartphone aus"
        : shopHash[0] < 190
          ? "in unserem Onlineshop"
          : "von Zuhause"
  const partOne = shopHash[1] < 127 ? "Schau dich um" : "Sieh dich um"
  const partTwo = shopHash[2] < 127 ? "lass dich inspirieren" : "entdecke unsere Auswahl"
  const sentenceOne = `${partOne}, ${partTwo} und bestell dann ganz bequem ${where}.`
  const assortment =
    shopHash[3] < 90 ? "unser gesamtes Sortiment" : shopHash[3] < 180 ? "alle unsere Artikel" : "unsere Produkte"
  const hereOnline = shopHash[4] < 127 ? "hier online" : "im Webshop"
  const assortmentLine = shopHash[5] < 127 ? `${assortment} ${hereOnline}` : `${hereOnline} ${assortment}`
  const sentenceTwo =
    shopHash[6] < 90
      ? `Wir bieten ${assortmentLine} an.`
      : shopHash[6] < 180
        ? `Du kannst ${assortmentLine} bestellen.`
        : `Wir haben ${assortmentLine} im Angebot.`
  const defaultDescription = shopHash[7] < 127 ? `${sentenceTwo} ${sentenceOne}` : `${sentenceOne} ${sentenceTwo}`

  const user = useCurrentUser()
  const [showSignUp, setShowSignUp] = useState(false)

  if (!shop) return null

  /** Handling shops with login required */
  if (shop.featureLoginRequired) {
    if (!user)
      return (
        <div className="tw-flex tw-flex-col tw-gap-4 tw-max-w-xl tw-mx-auto tw-h-screen tw-items-center tw-justify-center">
          <div className="tw-p-8 tw-bg-white tw-rounded-2xl">
            <h1 className="tw-text-2xl tw-font-bold">{showSignUp ? "Account erstellen" : "Login"}</h1>
            <div className="tw-w-full tw-space-y-2">
              {showSignUp ? (
                <EmailSignUpForm
                  extraEmailValidator={(email: string) =>
                    shop.allowedCustomers?.some(c => c.email.toLowerCase() === email.toLowerCase()) || false
                  }
                  skipEmailVerification={true}
                />
              ) : (
                <EmailLoginForm />
              )}
            </div>
            <button className="tw-text-sm tw-text-gray-500" onClick={() => setShowSignUp(!showSignUp)}>
              {showSignUp ? "Login" : "Account erstellen"}
            </button>
          </div>
        </div>
      )

    if (!shop.allowedCustomers?.some(c => c.email.toLowerCase() === user.email?.toLowerCase()))
      return <p>Fehlende Berechtigung.</p>
  }

  //Only to get the context on highest level
  return (
    <>
      <CommonHead
        title={`${shop?.name || "gastromia"} - Jetzt online bestellen`}
        description={shop?.description || shop?.customShopNotification || defaultDescription}
        image={
          shop.openGraphImage
            ? shop.openGraphImage.url
            : shop?.image && (typeof shop.image === "string" ? shop.image : shop.image?.url)
        }
        shopImage={typeof shop.image === "string" ? shop.image : shop.image?.url}
        url={`${origin}/bestellung`}
      />
      <Head>
        <script
          key="local-business"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": shop?.type
                ? ({
                    bakery: "Bakery",
                    farmersmarket: "GroceryStore",
                    restaurant: "Restaurant",
                    shop: "Store",
                    butcher: "GroceryStore",
                    others: "Store",
                  }[shop.type] ?? "Store")
                : "Store",
              ...(shop?.description ? { description: shop?.description } : {}),
              ...(shop?.contact?.companyName ? { legalName: shop?.contact?.companyName } : {}),
              "name": shop?.name || shop?.contact?.companyName,
              "address": {
                "@type": "PostalAddress",
                ...(shop?.contact?.address ? { streetAddress: shop?.contact?.address } : {}),
                ...(shop?.contact?.city ? { addressLocality: shop?.contact?.city } : {}),
                ...(shop?.contact?.zipCode ? { postalCode: shop?.contact?.zipCode } : {}),
                "addressCountry": shop?.contact?.country || "DE",
              },
              "currenciesAccepted": "EUR",
              "paymentAccepted": shop?.payment_options
                ?.filter(option => option.active)
                .map(option =>
                  option.value === "cash"
                    ? ["Cash"]
                    : option.value === "card"
                      ? ["Credit Card", "Stripe"]
                      : option.value === "paypal"
                        ? ["PayPal"]
                        : []
                )
                .join(", "),
              "telephone": shop?.contact?.phone,
              "email": shop?.contact?.email,
              "url": shop?.contact?.website ?? `${origin}/${shop?.shorthand}`,
              "menu": `${origin}/${shop?.shorthand}`,
              "logo": typeof shop?.image === "string" ? shop.image : shop?.image?.url,
            }),
          }}
        />
      </Head>
      <Theme uiColor={shop?.uiColor} customShopTheme={shop?.customTheme} />
      <ShopIdContextProvider shopId={shopId}>
        <MainView />
      </ShopIdContextProvider>
    </>
  )
}

export default NextOrderPage
